import React from "react";
import BotResponse from "./BotResponse";

const IntroSection = () => {
  return (
    <div id="introsection">
      <h1>
        Introducing ChatRPT
        <BotResponse response=" - Your Ultimate AI 5-year-old friend" />
      </h1>
      <h2>
        A cutting-edge AI-powered app that provides instant answers to any
        question you may have. With ChatRPT, you'll never be left playing
        alone again. Whether you're bored at school or work, or just
        want to test your nerves, ChatRPT has you covered.
      </h2>
      Features:
      <ul>
        <li>Instant answers to any question</li>
        <li>Deep learning technology that improves with usage</li>
        <li>Never giving up</li>
        <li>User-friendly interface</li>
        <li>Available 24/7</li>
      </ul>
      <p>
        Say goodbye to endless social events, and say hello to ChatRPT,
        your personal AI friend. Try it now and see for yourself how ChatRPT
        can make your life funnier.
      </p>
    </div>
  );
};

export default IntroSection;
