// Initial config

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCo9xdIIBelKaVjeCuTpVB7caiX9nOPMU8",
  authDomain: "chatrpt-fr.firebaseapp.com",
  projectId: "chatrpt-fr",
  storageBucket: "chatrpt-fr.appspot.com",
  messagingSenderId: "184768564682",
  appId: "1:184768564682:web:af1d0528f3b4070a791333",
  measurementId: "G-M09JLZ4W15"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const goggleAuthProvider = new GoogleAuthProvider();
const analytics = getAnalytics(firebaseApp);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(firebaseApp);
export { auth, goggleAuthProvider, db, analytics };